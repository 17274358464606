import React from "react"

const HowToBuy = () => {
  return (
    <div
      id="how_to_buy"
      className="relative flex flex-col min-h-full mb-[550px] md:mb-[500px] lg:mb-0 shadow-2xl shadow-black rounded-none z-30"
    >
      <div className="relative w-auto h-full">
        <img
          src="/img/sections/how-to-buy.png"
          alt="How To Buy"
          className="md:w-full w-auto h-full object-cover max-md:object-[-750px]"
        />
        <div className="absolute top-0 w-full left-0 h-20 bg-gradient-to-b from-[#000000] to-[#1a1a1a06]"></div>
      </div>

      <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>

      <div className="absolute top-0 left-1/2 w-full min-h-full flex flex-col justify-end  container pb-24 -translate-x-1/2 mt-[600px] md:mt-[300px] lg:mt-0">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
          <h2 className="text-[43px] lg:text-8xl lg:col-span-2 lg:hidden whitespace-nowrap text-center">
            How To Buy
          </h2>

          <div className="flex flex-col gap-y-2">
            <h2 className="text-2xl font-semibold">1 CREATE A WALLET</h2>
            <p className="text-sm">
              CREATE A WALLET THAT IS COMPATIBLE WITH KASPA BLOCKCHAIN. REMEMBER
              TO KEEP YOUR SEED PHRASE SAFE.
            </p>
          </div>

          {/* Mobile */}
          <div className="flex flex-col gap-y-2 lg:col-start-2 lg:hidden">
            <h2 className="text-2xl font-semibold">
              2 SEND KAS TO YOUR WALLET
            </h2>
            <p className="text-sm">
              AFTER COPYING $KAS ADDRESS ON THE WALLET, GO TO YOUR EXCHANGE AND
              WITHDRAW $KAS INTO YOUR WALLET TO PURCHASE YORAN TOKENS.
            </p>
          </div>

          {/* Desktop title */}
          <h2 className="text-6xl md:text-8xl lg:col-span-2 hidden lg:block text-center">
            How To Buy
          </h2>

          {/* Desktop */}
          <div className="flex-col gap-y-2 hidden lg:flex">
            <h2 className="text-2xl font-semibold">4 SIGN & PAY</h2>
            <p className="text-sm">
              ONCE YOU DECIDE HOW MANY YORAN TOKENS YOU WILL PURCHASE AND CLICK
              “MINT”, YOU WILL FACE WITH TRANSACTION DETAILS. IF DETAILS ARE
              CORRECT, CLICK “SIGN & PAY”.
            </p>
          </div>

          {/* Desktop */}
          <div className="flex-col gap-y-2 lg:col-start-2 hidden lg:flex">
            <h2 className="text-2xl font-semibold">
              2 SEND KAS TO YOUR WALLET
            </h2>
            <p className="text-sm">
              AFTER COPYING $KAS ADDRESS ON THE WALLET, GO TO YOUR EXCHANGE AND
              WITHDRAW $KAS INTO YOUR WALLET TO PURCHASE YORAN TOKENS.
            </p>
          </div>

          <div className="flex flex-col gap-y-2">
            <h2 className="text-2xl font-semibold">3 CLICK “MINT”</h2>
            <p className="text-sm">
              MAKE SURE YOU HAVE RECEIVED $KAS. THEN, CLICK ON “MINT” BUTTON TO
              PURCHASE YORAN, AND ENTER THE TICKER “YORAN”.
            </p>
          </div>

          {/* Mobile */}
          <div className="flex flex-col gap-y-2 lg:col-start-2 lg:hidden">
            <h2 className="text-2xl font-semibold">4 SIGN & PAY</h2>
            <p className="text-sm">
              ONCE YOU DECIDE HOW MANY YORAN TOKENS YOU WILL PURCHASE AND CLICK
              “MINT”, YOU WILL FACE WITH TRANSACTION DETAILS. IF DETAILS ARE
              CORRECT, CLICK “SIGN & PAY”.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToBuy
