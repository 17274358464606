import React from "react"

const About = () => {
  return (
    <div
      id="about"
      className="relative flex flex-col min-h-full shadow-xl shadow-black rounded-none z-50 "
    >
      <img
        src="/img/sections/about-us.png"
        alt="About Us"
        className="w-auto h-full object-cover max-md:hidden"
      />
      <img
        src="/img/sections/mobile-about-us.png"
        alt="About Us"
        className="w-auto h-full object-cover md:hidden"
      />

      <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>

      <div className="absolute top-0 left-1/2 w-full h-full flex flex-col justify-end md:justify-center container pb-24 md:pb-0 -translate-x-1/2 max-md:mt-12">
        <h2 className="text-2xl md:text-6xl">About</h2>
        <h2 className="text-6xl md:text-8xl">Yoran</h2>

        <div className="flex flex-col mt-4 gap-y-6 text-base md:text-[17px] max-w-sm font-semibold">
          <p>
            Yoran The Cavalıer ıs a KRC20 memecoın honorıng the DAGs, the heroıc
            trıo who have boldly addressed the blockchaın trılemma.
          </p>
          <p>
            Yoran, a cheerful and spırıted cat, celebrates dags’ vıctorıes wıth
            songs and poetry, yet prefers nappıng durıng battles and ındulgıng
            ın the publıc’s fısh stash when he awakens.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
