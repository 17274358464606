import React from "react"

const Navbar = () => {
  return (
    <div className="flex-wrap *:text-lg items-center justify-center gap-8 py-12 container fixed top-0 left-1/2 z-[500] -translate-x-1/2 hidden lg:flex">
      <a href="/" className="text-white">
        Home
      </a>
      <a href="/#about" className="text-white">
        About
      </a>
      <a href="/#tokenomics" className="text-white">
        Tokenomıcs
      </a>
      <a href="/#how_to_buy" className="text-white">
        How to Buy
      </a>
      <a href="/#faq" className="text-white">
        FAQ
      </a>
      <a href="/#contact" className="text-white">
        Follow Us
      </a>
    </div>
  )
}

export default Navbar
