import React from 'react';

const ArrowUp = () => {
    const [scroll, setScroll] = React.useState(0);

    React.useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    if (scroll < 300) return null;
    
    return (
        <div className="hidden lg:fixed bottom-12 right-12 z-50">
            <a href="#top" className='flex flex-col gap-y-1'>
                <img src="/img/ui/arrow-up.png" alt="Arrow Up" className='w-12 object-cover mx-auto' />
                <p className='text-lg text-[#E7C28B]'>HOME</p>
            </a>
        </div>
    );
}

export default ArrowUp;