import React, { useState } from "react"

const faq = [
  {
    question: "HOW CAN I PURCHASE YORAN?",
    answer:
      "Yoran can be purchased on the Kaspa blockchain. To purchase Yoran, you need to have KAS in your wallet. You can buy KAS from exchanges that support KAS and then transfer it to your wallet.",
  },
  {
    question: "DOES YORAN OFFER AIRDROP?",
    answer:
      "Yoran's making history. To show our love and celebrate a cutting-edge effort by Kaspa developers and strengthen our bond, Yoran will allocate a massive 28.7% of our total supply directly to community—the heart and soul of Yoran The Cavalier.",
  },
  {
    question: "IS YORAN LISTED ON EXCHANGES?",
    answer:
      "While YORAN isn’t listed on exchanges just yet, Yoran's already making moves behind the scenes! We're in talks with exchanges and gearing up for the big moment after our public sale.",
  },
  {
    question: "CAN I STAKE YORAN?",
    answer:
      "As soon as everything is technically set, you’ll be able to stake your YORAN tokens and become an even bigger part of our adventure!",
  },
]

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div
      id="faq"
      className="relative flex flex-col min-h-full  md:mb-[300px] max-md:mt-[150px] lg:mb-0 shadow-xl shadow-black rounded-none z-20 max-md:mb-[150px]"
    >
      <div className="relative w-auto h-full">
        <img
          src="/img/sections/faq.png"
          alt="FAQ"
          className="md:w-full w-auto h-full object-cover max-md:hidden"
        />
        <div className="absolute top-0 w-full left-0 h-20 bg-gradient-to-b from-[#000000] to-[#1a1a1a06]"></div>
        <div className="absolute bottom-0 w-full left-0 h-20 bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>
      </div>
      <div className="*:md:hidden relative">
        {/* <div className="absolute bottom-0 text-white  bg-gradient-to-r from-[#000000] to-[#13131306] h-60 w-full"></div> */}
        <img
          src="/img/sections/mobile-faq.png"
          alt="FAQ"
          className="w-full md:h-full object-contain"
        />
        <img
          src="/img/sections/blur.png"
          alt="FAQ"
          className="md:w-auto max-md:w-full md:h-full object-contain -mt-20"
        />
        <div className="absolute top-0 w-full left-0 h-20 bg-gradient-to-b from-[#000000] to-[#1a1a1a06]"></div>
      </div>

      <div className="absolute bottom-0 left-0 md:w-1/2 xs:w-full sm:w-full  h-full max-md:w-full">
        <div className="absolute top-0 left-1/2 w-full h-full flex flex-col justify-end md:justify-center container pb-10 md:pb-0 -translate-x-1/2 mt-[150px] md:mt-[200px] lg:mt-0">
          <h2 className="text-6xl md:text-8xl text-center lg:text-left">FAQ</h2>

          <div className="w-full flex flex-col max-w-md gap-y-6 mt-6 lg:mt-24">
            {faq.map((item, index) => (
              <div
                className="flex gap-x-4 cursor-pointer"
                key={index}
                onClick={() => setActiveIndex(index)}
              >
                <img
                  src="/img/ui/faq/arrow-right.png"
                  alt="FAQ"
                  className="h-8 w-auto object-cover"
                />

                <div className="w-[400px] flex flex-col gap-y-2">
                  <h2 className="text-xl font-semibold">{item.question}</h2>
                  <p className={`text-sm ${activeIndex !== index && "hidden"}`}>
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
