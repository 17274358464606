import { useState, useEffect, useRef } from "react"
import About from "./components/home/About"
import FAQ from "./components/home/FAQ"
import FollowUs from "./components/home/FollowUs"
import Hero from "./components/home/Hero"
import HowToBuy from "./components/home/HowToBuy"
import Tokenomics from "./components/home/Tokenomics"
import ArrowUp from "./components/layout/ArrowUp"
import Navbar from "./components/layout/Navbar"

function App() {
  const [showScroll, setShowScroll] = useState(true)
  const heroRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowScroll(false) // Hero bileşeni görünürse scroll.svg gizlenir
        } else {
          setShowScroll(true) // Hero bileşeni görünmezse scroll.svg görünür
        }
      },
      { threshold: 0.1 } // Bileşenin %10'u görünür olduğunda tetiklenir
    )

    if (heroRef.current) {
      observer.observe(heroRef.current)
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current)
      }
    }
  }, [])

  return (
    <>
      <div className="flex flex-col min-h-full bg-black text-white">
        <Navbar />
        <div ref={heroRef} className="max-md:hidden">
          <Hero />
        </div>
        <Hero className="md:hidden" />
        <About />
        <Tokenomics />
        <HowToBuy />
        <FAQ />
        <FollowUs />
        <ArrowUp />
      </div>

      {showScroll && (
        <div className="min-h-full md:relative max-md:hidden">
          <a
            href="/#home"
            className="fixed bottom-10 right-10 z-[51] text-white"
          >
            <img src="/img/sections/scroll.svg" alt="How To Buy" />
          </a>
        </div>
      )}
    </>
  )
}

export default App
