import React from "react"

const Hero = ({ className }) => {
  const imagePath = "https://via.placeholder.com/100"
  const [showImage, setShowImage] = React.useState(false)
  return (
    <div
      id="home"
      className={`relative flex flex-col min-h-full h-dvh ${className}`}
    >
      <div className="relative w-auto h-full ">
        <img
          src="/img/sections/hero.png"
          alt="Hero"
          className="md:w-full w-auto h-full object-cover max-md:object-[-220px]"
        />
        <div className="absolute top-0 w-full left-0 h-24 bg-gradient-to-b from-transparent via-black via-0% to-transparent"></div>
      </div>

      <div className="absolute top-0 left-1/2 w-full h-full z-10 flex flex-col gap-y-6 items-center justify-center container text-center -translate-x-1/2">
        <img
          src="/img/ui/hero/welcome.png"
          alt="Welcome"
          className="h-12 md:h-16 w-auto object-cover"
        />
        <img
          src="/img/ui/hero/yoran.png"
          alt="Yoran"
          className="h-16 md:h-32 w-auto object-cover"
        />
        <p className="font-semibold text-base md:text-xl">
          A TRIBUTE TO HEROIC TRIO: BLOCKDAG, GHOSTDAG & DAGKNIGHT
        </p>

        <a href="/#how_to_buy" className="flex justify-center items-center">
          <img
            src={
              showImage ? "/img/ui/hero/btn-hover.png" : "/img/ui/hero/btn.png"
            }
            onMouseEnter={() => setShowImage(true)}
            onMouseLeave={() => setShowImage(false)}
            alt="How to Buy"
            className={` max-sm:w-[80%] md:h-[65px] h-[45px] ${
              showImage ? "" : ""
            }`}
          />
        </a>
      </div>
      <a
        href="/#about"
        className="absolute bottom-10 transform -translate-x-1/2 left-1/2 z-[51] text-white max-md:hidden"
      >
        <img src="/img/ui/hero/welcome-scroll.png" alt="scroll" />
      </a>
    </div>
  )
}

export default Hero
