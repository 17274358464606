import React from "react"

const FollowUs = () => {
  return (
    <div
      id="contact"
      className="relative flex flex-col md:min-h-full max-md:h-[85%]"
    >
      <img
        src="/img/sections/follow-us.png"
        alt="Follow Us"
        className="w-auto h-full object-cover max-md:hidden"
      />
      <img
        src="/img/sections/mobile-follow-us.png"
        alt="Follow Us"
        className="w-auto h-full object-cover md:hidden "
      />
      <div className="absolute top-0 w-full left-0 h-20 bg-gradient-to-b from-[#000000] to-[#1a1a1a06]"></div>
      <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>
      <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>
      <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>
      <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>

      <div className="absolute top-0 left-1/2 w-full h-full flex flex-col items-center lg:items-end justify-end md:justify-center container pb-10 md:pb-0 -translate-x-1/2 max-md:mt-[100px]">
        {" "}
        <div className="absolute bottom-0 text-white  bg-gradient-to-t from-[#000000] to-[#1a1a1a01] h-24 w-full md:hidden"></div>
        <div className="flex flex-col items-center justify-center max-w-screen-sm w-full text-center gap-y-6">
          <h2 className="text-5xl md:text-8xl whitespace-nowrap">FOLLOW US</h2>

          <button className="flex items-center justify-center mt-4 gap-2 text-base md:text-lg max-w-sm hover:-translate-y-1 transition-all">
            <img
              src="/img/ui/faq/arrow-right.png"
              alt="Twitter"
              className="h-8 w-auto object-cover !bg-transparent"
            />
            <span>SOCIAL MEDIA</span>
          </button>

          <div className="flex flex-wrap items-center justify-center gap-x-14 mt-6">
            <a
              href="https://t.me/yoranthecavalier"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition-all"
            >
              <img
                src="/img/ui/follow-us/telegram.png"
                alt="Telegram"
                className="h-8 w-auto object-cover"
              />
            </a>
            
            <a
              href="https://x.com/YoranTheKnight?t=9tlYz8wvFH70oOX7qj5Bpg&s=08"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-110 transition-all"
            >
              <img
                src="/img/ui/follow-us/twitter.png"
                alt="Twitter"
                className="h-8 w-auto object-cover"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FollowUs
