import React from "react"

const Tokenomics = () => {
  return (
    <div
      id="tokenomics"
      className="relative flex flex-col min-h-full shadow-xl shadow-black rounded-none z-40"
    >
      <img
        src="/img/sections/tokenomics.jpg"
        alt="tokenomics"
        className="w-auto h-full object-cover max-md:object-[-650px]"
      />

      <div className="absolute bottom-0 left-0 w-full h-[70%] bg-gradient-to-t from-[#000000] to-[#1a1a1a06]"></div>

      <div className="absolute top-0 left-1/2 w-full h-full flex flex-col justify-end container pb-24 -translate-x-1/2">
        <h2 className="max-md:text-center text-5xl md:text-6xl lg:text-8xl">
          tokenomIcs
        </h2>
        <div className="flex flex-col mt-5 max-w-screen-2xl w-full mx-auto max-lg:hidden">
          <div className="grid grid-cols-6 gap-x-2 items-center px-5 -mb-3 *:xl:text-lg *:2xl:text-xl *:text-[#7BDCB5]">
            <h3>TOTAL SUPPLY</h3>
            <h3>PUBLIC</h3>
            <h3>BURNED AMOUNT</h3>
            <h3>LIQUIDITY</h3>
            <h3>TEAM</h3>
            <h3>MARKETING</h3>
          </div>
          <img src="/img/sections/arrow.svg" alt="arrow" />
          <div className="grid grid-cols-6 gap-x-2 items-center px-5 -mt-3 xl:text-lg 2xl:text-3xl *:xl:text-lg *:2xl:text-xl">
            <span>287,000,000</span>
            <span>57.29%</span>
            <span>24.27%</span>
            <span>12.70%</span>
            <span>2.87%</span>
            <span>2.87%</span>
          </div>
        </div>
        <div className="lg:hidden">
          <img
            src="/img/sections/arrow.png"
            alt="arrow"
            className="h-10 w-full"
          />
          <div className="grid grid-cols-2 gap-y-5 mt-1">
            <div className="flex flex-col items-start">
              <h3 className="text-lg text-[#7BDCB5]">TOTAL SUPPLY</h3>
              <span className="text-lg pl-2">287,000,000</span>
            </div>
            <div className="flex flex-col items-end">
              <h3 className="text-lg text-[#7BDCB5]">PUBLIC</h3>
              <span className="text-lg pr-2">57.29%</span>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-lg text-[#7BDCB5]">AIRDROP</h3>
              <span className="text-lg pl-2">28.70%</span>
            </div>
            <div className="flex flex-col items-end">
              <h3 className="text-lg text-[#7BDCB5]">LIQUIDITY</h3>
              <span className="text-lg pr-2">8.27%</span>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-lg text-[#7BDCB5]">TEAM</h3>
              <span className="text-lg pl-2">2.87%</span>
            </div>
            <div className="flex flex-col items-end">
              <h3 className="text-lg text-[#7BDCB5]">MARKETING</h3>
              <span className="text-lg pr-2">2.87%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tokenomics
